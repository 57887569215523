
::v-deep .v-dialog {
    border-radius 18px
    border: 2px solid $color-bnn
}

::v-deep .v-dialog {
    border-radius 18px
    border: 2px solid $color-bnn
    // max-height fit-content
    // overflow-y auto
    margin 20px 0 0

    @media screen and (min-width: 768px) {
        margin 0
    }

    @media screen and (min-width: 1024px) {
        margin 0
    }
}

.pdp-modal-container {
    margin 0 auto !important
    width 100%
    max-width 100dvw
    min-width 100dvw
    position relative

    display flex
    flex-direction column

    padding 24px

    @media screen and (min-width: 768px) {
        width 100%
        min-width 692px
        max-width 692px
    }

    @media screen and (min-width: 1024px) {
        width 100%
        max-width 1029px
        min-width 1029px
    }
}

.pdp-modal-content {
    width 100%
    height fit-content
    min-height fit-content
    padding 12px

    @media screen and (min-width: 768px) {
        padding 12px 52px 52px
    }
}

.header-modal {
    display: flex
    justify-content: space-between
    margin-bottom: 16px
}

.btn-close {
    width: 40px;
    height: 40px;

    background: #e8e8e8;
    border-radius: 50% !important;

    z-index 10

    &:hover {
        .icon-close {
            color: rgba(0,0,0,0.72)
        }
    }

    &:focus {
        outline: .125rem solid #0071e3;
        outline-offset: .125rem;
        outline-offset: .125rem;
    }

    .icon-close {
        color: rgba(18,18,18,0.56)
    }
}
